import StatusCircle from "./StatusCircle";
import ResetDevice from "./ResetDevice";
import { GlobalModal as Modal } from "../Modal";
import { useHistory } from "react-router-dom";
import withDashboard from "../../redux/connectors/dashboard";
import { trackEvent } from "../../utils/utils";
import { digitalDataButtonClick } from "../../utils/adobe-analytics";

const ServiceStatus = (props) => {
  const {
    content,
    service,
    autoRefill,
    currentLocale,
    deactivatedAccount,
    hsdService,
    autoRefillEnabled,
    dashboardContent,
  } = props;
  const getCircleColor = (days) => {
    if (days <= 10 && days > 0) return "#FFA700";
    else if (days <= 0) return "#c90318";
    else return "#47C684";
  };
  const getServiceStateColor = (days) => {
    if (days < 0) return "#c90318";
    else return "#47C684";
  };
  const getServiceStateStyle = (days) => {
    const stateStyle = {
      color: getServiceStateColor(days),
    };
    return stateStyle;
  };
  const expired = (days) => {
    if (days > 0) {
      return false;
    } else {
      return true;
    }
  };
  const goToRefill = (e) => {
    e.preventDefault();
    e.stopPropagation();
    history?.push(`/${currentLocale}/refill`);
  };
  const trackClick = () => {
    trackEvent({
      event: "dashboard",
      dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
      dashboardEvent: "helpful links",
      dashboardDetail: "reset my internet router",
    });
  };
  const sendRefillAnalytics = (component) => {
    trackEvent({
      event: "dashboard",
      dashboardPage: document.title.replace(" - Xfinity Prepaid", ""),
      dashboardEvent: "refill now",
      dashboardDetail: component,
    });
  };
  const renderRefillButton = (days) => {
    if (days <= 10) {
      return (
        <button
          data-testid="refill-now"
          className="button button--hollow-white"
          onClick={(e) => {
            goToRefill(e);
            sendRefillAnalytics(service.name);
          }}
          data-tracking={digitalDataButtonClick(
            "refill",
            content.dashboardContent.dashboardArea.prepaidServices.refillNow
              .text
          )}
        >
          {
            content.dashboardContent.dashboardArea.prepaidServices.refillNow
              .text
          }
        </button>
      );
    } else {
      return null;
    }
  };
  const history = useHistory();
  const checkInternetStatus = () => {
    if (service.daysRemaining > 0) {
      return (
        <div>
          <button
            className="back-button"
            onClick={() => history?.goBack()}
            data-tracking={digitalDataButtonClick(
              "back",
              content.dictionaryContent.global.back
            )}
          >
            {content.dictionaryContent.global.back}
          </button>
          <div className="title">{service.name}</div>
          <div>
            <StatusCircle
              daysLeft={service.daysRemaining}
              radius={75}
              stroke={3}
              showTooltip={!autoRefill}
              strokeColor={getCircleColor(service.daysRemaining)}
              tooltipMessage={
                content.dashboardContent.dashboardArea.prepaidServices
                  .hsDServiceIsRequired
              }
            />
          </div>
        </div>
      );
    } else {
      return (
        <div className="service-status__expired">
          <button
            className="back-button"
            onClick={() => history?.goBack()}
            data-tracking={digitalDataButtonClick(
              "back",
              content.dictionaryContent.global.back
            )}
          >
            {content.dictionaryContent.global.back}
          </button>
          <div className="title">
            {service.name} {content.dictionaryContent.global.service}
          </div>
          <img
            title="expired"
            className="icon icon-expired pulse"
            alt="service expired"
            src="/site-assets/img/icon-expired.svg"
          />
          <div className="service-status__expired-warning">
            {service.name} {content.dictionaryContent.global.serviceHasExpired}
          </div>
          <Modal
            buttonClasses={"button--link--underline"}
            body={
              content.dashboardContent.dashboardArea.prepaidServiceStates
                .prepaidInternetHasExpired.text
            }
            title={
              content.dashboardContent.dashboardArea.prepaidServices
                .prepaidInternetHasExpired
            }
            ctaText={
              content.refillContent.refillFlow.cart.whatDoesThisMean.text
            }
            type="expired"
          />
          {renderRefillButton(service.daysRemaining)}
        </div>
      );
    }
  };

  return (
    <div className="service-detail">
      <div className="service-detail-top">
        <div className="main__width-constraint">{checkInternetStatus()}</div>
        <div className="autoEnrollSection">
          {!autoRefillEnabled &&
            !deactivatedAccount &&
            hsdService?.daysRemaining > 0 && (
              <a
                className="button button--inline"
                href={`/${currentLocale}/dashboard/auto-refill`}
              >{`${
                !autoRefillEnabled
                  ? dashboardContent?.dashboardArea?.autoRefill
                      ?.enrollInAutoRefill?.text
                  : dashboardContent?.dashboardArea?.autoRefill
                      ?.cancelAutoRefill?.text
              }`}</a>
            )}
        </div>
      </div>
      <div className="service-detail-bottom">
        <div className="main__width-constraint">
          <div className="service-detail-bottom-left">
            <span className="refill-label">
              {expired(service.daysRemaining) ? "Expired" : "Refill by"}{" "}
            </span>
            {service.expirationDate}
          </div>
          <div
            style={getServiceStateStyle(service.daysRemaining)}
            className="service-detail-bottom-right"
          >
            <span className="service-status-label">
              {content.dictionaryContent.global.service}{" "}
            </span>{" "}
            {expired(service.daysRemaining) ? "Expired" : "Active"}
          </div>
          {service.deviceLob === "HSD" && (
            <div className="service-detail-bottom-reset-block">
              {
                content.dashboardContent.dashboardArea.prepaidServices
                  .havingTroubleConnecting
              }
              <Modal
                buttonClasses={"button--link--underline"}
                ctaText={
                  content.refillContent.refillFlow.speedBoost.resetDevice.text
                }
                modalWrapClasses="display-inline"
                component={<ResetDevice />}
                onOpen={() => {
                  trackClick();
                }}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withDashboard(ServiceStatus);
